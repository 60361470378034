// Packages
import { useState } from 'react';


// Components
import Avatar from 'components/Avatar';
import ChangePhoto from 'components/Auth0/ChangePhoto';


// Assets
import './styles.css';
import Config from "../../../other/Config";


const Screen = props => {

	// Props
	const { user } = props;


	// State
	const [ avatarUrl, setAvatarUrl ] = useState(user['https://' + Config.AUTH0_DOMAIN + '/picture_url']);


	return (
		<>
			<div className="UserProfileScreen__title">
				Change Your Profile Photo
			</div>

			<div className="ChangePhoto__avatar">
				<Avatar size="large" firstname={user.given_name} lastname={user.family_name} showName={false} picture={avatarUrl}/>
			</div>

			<ChangePhoto onChange={(newAvatarUrl) => {
				setAvatarUrl(newAvatarUrl);
			}}/>
		</>
	);

}

export default Screen;