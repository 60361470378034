// Components
import Tile from 'components/Tile'


// Assets
import './styles.css'


import Config from 'other/Config';


const Tiles = () => {

    const ITEMS = [
        {
            name: 'ORDERING SYSTEM',
            tileClass: 'Tile--ordering',
            description: 'Place your customer orders, view your sales and commission, and manage your Ambassador account.',
            icon: 'icon-ordering.svg',
            url: Config.ORDERING_URL
        },
        {
            name: 'PENNY',
            tileClass: 'Tile--ami',
            description: 'Your powered up personal assistant: make customer communication, sharing content and growing your business effortless.',
            icon: 'penny2.svg',
            url: Config.AMI_URL
        },
        {
            name: 'Stock & Delivery',
            tileClass: 'Tile--stock',
            description: 'Stay in-the-know with the latest stock and delivery updates across our entire freshly made range.',
            icon: 'icon-delivery.svg',
            url: Config.STOCKUPDATES_URL
        },
        {
            name: '',
            tileClass: 'Tile--market',
            description: 'Elevate your Ambassador edit with branded business and get-together essentials, clothing, homeware and more!',
            icon: 'icon-market.svg',
            url: Config.MARKET_URL
        },
        {
            name: '',
            tileClass: 'Tile--lounge',
            description: 'Packed with training tools and learning, soak up an abundance of knowledge and find Ambassador-only information here.',
            icon: 'icon-lounge.svg',
            url: Config.LOUNGE_URL
        },
        {
            name: '',
            tileClass: 'Tile--social',
            description: 'From branded email templates to social assets, discover all your customer content and marketing tools.',
            icon: 'icon-social.svg',
            url: Config.SOCIAL_URL
        }
    ]

    return (
        <div className="Tiles Container">
            {ITEMS.map((item) => {
                return <Tile key={item.tileClass} {...item}/>
            })}
        </div>
    )
}

export default Tiles